
var images = null;
var jumpTo = false;

$(document).ready(function() {

	var minHeight = setMinHeight();
	setHeights(minHeight);
	setTextContainerMargins();

	//Erste x images laden
	loadImages(0,10);

	var _skrollr = initSkrollr();


	//Nav ScrollTo
	$('#side-nav a:not(.impressum)').on('click', function(e) {
		e.preventDefault();
		var _this = $(this);

		var _target = _this.attr('href');
		if(_target == "#slide-images") {
			loadImages(0,5);


			scrollToEle($(_target).eq(0),0,minHeight/2 + 10);

			//scrollToEle($(_target).eq(0),0,minHeight/2 + 10);
			setTimeout(function() {setNextSlide($('.slide').eq(0),'down')},50);
			addHashToUrl('#slide-images');
		}
		else if(_target == "#slide-start") {
			loadImages(0,5);
			scrollToEle($(_target), 0,0);
			addHashToUrl('slide-start');

			setTimeout(function(){
				setNextSlide($('.slide').eq(0),'down');
				$('.slide').removeClass('active-slide');
			},100);
		}
		else if(_target == '#slide-ausbildung') {
			scrollToEle($(_target), 0,0);
			addHashToUrl('slide-ausbildung');

			loadImages($('.slide').length - 5, 5);
		}
		else if(_target == '#slide-kontakt') {
			scrollToEle($(_target), 0,minHeight*0.25);
			addHashToUrl('slide-kontakt');
			loadImages($('.slide').length - 5, 5);
		}

		else {
			loadImages($('#slide-images .slide').length-5, 5);
			scrollToEle($(_target), 0,0);
		}

	});

	$('#side-nav .impressum').on('click', function(e) {
		e.preventDefault();
		$('#slide-impressum').addClass('open');
		$('body').css({
			overflow: 'hidden',
			height: '100vh'
		});
	});

	$('#slide-impressum .impressum-close').on('click', function() {
		$('#slide-impressum').removeClass('open');
		$('body').css({
			overflow: '',
			height: ''
		});
	});


	var svg_gruende = $('#svg-cloud-wrapper svg g');
	//Svg ScrollTo
	svg_gruende.on('click', function(e) {
		var _this = $(this);
		var _target = $(_this.attr('data-anchor'));

		var _target_index = _target.index();

		loadImages(_target_index-2, 4);
		scrollToEle($(_target).eq(0),0,minHeight/2 + 10);

	});


	$('#start-arrow a').on('click', function(e) {
		e.preventDefault();
		var _this = $(this);
		var curslide = $('.active-slide').eq(0);


		if(curslide.length == 0) {
			if(mobileCheck()) {
				scrollToEle($('.slide').eq(0),600,minHeight/2 + 75);
			}
			else {
				scrollToEle($('.slide').eq(0),600,minHeight/2 +10);
			}

			return;
		}

		var curIndex = curslide.index() + 1;
		if(curIndex < 100) {
			addHashToUrl($('.slide').eq(curIndex).attr('id'));
			if(mobileCheck()) {
				scrollToEle($('.slide').eq(curIndex),600,minHeight/2 + 75);
			}
			else {
				scrollToEle($('.slide').eq(curIndex),600,minHeight/2 + 10);
			}

		}
		else {
			scrollToEle($('#slide-ausbildung'),600,0);
		}

	});


	//Start animation after 500ms
	setTimeout(function() {
		var svg_groups = $('#svg-cloud-wrapper svg g');
		var groupLen = svg_groups.length;
		for(var i = 0; i < groupLen; i++) {
			var g = svg_groups.eq(i);
			var oldClasses = g.attr('class');
			g.attr('class', oldClasses + ' visible');
		}
	},500);


	setNbrCircleColor($('#slide-images .slide').eq(0).attr('data-nbr-gradientClass'));

	$(window).resize(function() {
		var minHeight = setMinHeight();
		setHeights(minHeight);
		setTextContainerMargins();

	});

	$(window).on('orientationchange', function() {
		var minHeight = setMinHeight();
		setHeights(minHeight);
		setTextContainerMargins();
		//_skrollr.refresh();
	});
});



/**
 * Returns true if is mobile
 * else false
 */
function mobileCheck() {
	var isMobile = false;
	if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
		isMobile = true;
		if(!$('body').hasClass('isMobile')) {
			$('body').addClass('isMobile');
		}
	}
	return isMobile;
}


/**
 * Set Min Height for height Calculations
 *
 */
function setMinHeight() {
	var minHeight = 0;
	if( mobileCheck() ) {
		var orient = window.orientation;
		if( orient == 0 || orient == 180 ) { minHeight = screen.height; }
		else if( orient == -90 || orient == 90 ){ minHeight = screen.width; }
	}
	else { minHeight = $(window).innerHeight();	}
	return minHeight;
}


/**
 * set Element Heights based on minHeight
 */
function setHeights(minHeight) {
	//Image Slides
	var slides = $('#slide-images .slide');
	slides.css('height',(2*minHeight)+"px");
	slides.find('.gradient, .image-wrapper, .content-container').css('height', (2*minHeight)+"px");

	//Start Slide
	var slideStart = $('#slide-start');
	slideStart.css('height', minHeight+"px");
	slideStart.find('.content-container,image-wrapper').css('height', minHeight+"px");

	//Ausbildung Slide
	//$('#slide-ausbildung').css('height', minHeight+"px");

	//Kontakt Slide
	$('#slide-kontakt, #slide-kontakt .image-wrapper').css('height', 1.25*minHeight+"px");
}


/**
 * Set TextContainer negativ margin
 */
function setTextContainerMargins() {
	var container = $('#slide-images .article-box');
	var containerLen = container.length;
	for(var i = 0; i < containerLen; i++) {
		var ele = container.eq(i);

		ele.css('margin-top', -(parseInt(ele.outerHeight()*0.5))+"px");
	}
}


/**
 * Get Image sources from data-attribute
 *
function getImages() {
	var imgArr = [];

	var slide_images = $('#slide-images .slide');
	var slidesLen = slide_images.length;
	for(var i = 0; i < slidesLen; i++) {
		imgArr[i] = slide_images.eq(i).attr('data-bg-img');
	}
	return imgArr;
}
*/

/**
 * Init for image-preloader
 *
function initImgPreloader(images_src) {
	var imgLen = images_src.length;
	var tempArr = [];
	for(var i = 0; i < imgLen; i++) {
		tempArr[i] = {
			src: images_src[i],
			loaded: false
		}
	}
	return tempArr;
}
*/

/**
 * LoadImages from array
 */
function loadImages(start,limit) {

	var end = start + limit;
	var slide_images = $('#slide-images .slide');

	for(var i = start; i < end; i++) {
		if(slide_images[i] !== undefined){
			loadImage($(slide_images[i]));
		}
	}
}


/**
 * LoadImages from array
 */
function unLoadImages(start,limit) {
	var end = start + limit;
	var slide_images = $('#slide-images .slide');

	for(var i = start; i < end; i++) {
		if(slide_images[i] !== undefined){
			unLoadImage($(slide_images[i]));
		}
	}
}

/**
 * Load an single image
 */
function loadImage(img) {
	if(!img.hasClass('image-loaded')){
		var src = img.attr('data-bg-img');
		var prePath = './fileadmin/Resources/Public/Images/Layout/';
		if(mobileCheck()) {
			prePath += 'mobile/';
		}
		else {
			prePath += 'bg_images/';
		}

		var src = prePath + src;

		var _img = $( '<img src="' + src + '">' );

		//Cached?
		if( _img[0].width ){ _img.trigger( 'load' ); }

		img.addClass('image-loaded');
		_img.on( 'load', function(){
			img.find('.bg-img').css( 'background-image', 'url(' + src + ')' );


		} );
	}
}


/**
 * Load an single image
 */
function unLoadImage(img) {
	if(img.hasClass('image-loaded')){
		img.find('.bg-img').css( 'background-image', '' );
		img.removeClass('image-loaded');
	}
}

/**
 * Skrollr init
 */
function initSkrollr() {
	var s = skrollr.init({
		forceHeight: false,
		constants: setSkrollrConstants(),
		keyframe: function(element, name, direction) {

			setNavItems(element, name, direction);
			if(name == 'dataCenter') {

				skrollrImgLoad(element, name, direction);
				setNumberCircleBackground(element, name, direction);

				var nextSlide = setNextSlide($(element), direction);
				if(!jumpTo)
					addHashToUrl($('.slide').eq(nextSlide).attr('id'));
			}


			if(direction == 'up' && name == "dataCenterTop" && $(element).attr('id') == 'slide-start') {
				$('.slide').removeClass('active-slide');
			}




		},
		mobileCheck: function() {
			//hack - forces mobile version to be off
			return false;
		}
	});
	return s;
}

/**
 * Set Skrollr Constants
 * returns constants as object
 */
function setSkrollrConstants() {
	var skrollrConstants = {};
	//ToDo

	return skrollrConstants;
}

/**
 * Image load on scroll
 */
function skrollrImgLoad(element, name, direction) {
	if(jumpTo  || name !== 'dataCenter') {
		return;
	}

	var elementIndex = $(element).index();

	//check next 5 images
	var blocksize = 5;

	if(direction === 'up') {
		//if(elementIndex == 0) return;

		var minLen = elementIndex - blocksize;
		loadImages(elementIndex-blocksize,blocksize);
		unLoadImages(elementIndex+blocksize,blocksize);
	}
	if(direction === 'down') {
		loadImages(elementIndex,blocksize);
		unLoadImages(elementIndex-blocksize*2,blocksize);
	}
}

function setNumberCircleBackground(ele, name, dir) {
	var _ele = $(ele);
	if(!_ele.hasClass('slide')) {
		return;
	}
	var slides = $('#slide-images .slide');

	var curIndex = _ele.index();
	var nextIndex = 0;

	if(dir === 'down') {
		nextIndex = curIndex;
	}
	if(dir === 'up' && curIndex > 0) {
		nextIndex = curIndex-1;
	}

	var curSlide = slides.eq(nextIndex);
	var curGradientClass = curSlide.attr('data-nbr-gradientClass');

	setNbrCircleColor(curGradientClass,nextIndex);
}

function setNbrCircleColor(gradientClass,i) {

	var bgColor = "";

	var slideBg = $('.slide .gradient').eq(i).css('background-image');

	switch (slideBg) {
		case 'linear-gradient(135deg, rgb(186, 10, 126) 25%, rgb(87, 191, 199) 75%)': bgColor = "#ADCB53";break;
		case 'linear-gradient(135deg, rgb(87, 191, 199) 25%, rgb(173, 203, 83) 75%)': bgColor = "#F49B00";break;
		case 'linear-gradient(135deg, rgb(173, 203, 83) 25%, rgb(244, 155, 0) 75%)': bgColor = "#BA0A7E";break;
		case 'linear-gradient(135deg, rgb(244, 155, 0) 25%, rgb(186, 10, 126) 75%)': bgColor = "#57BFC7";break;
		default: bgColor = "#ADCB53";break;
	}

	$('#number-circle').css('background',bgColor);
}

function setNavItems(ele, name, dir) {
	if(name !== 'dataCenterBottom') {
		return;
	}
	var curIndex = $(ele).parent().index();
	var nextIndex = 0;

	var respo_menu = $('#responsiveMenu .menu li');
	respo_menu.removeClass('current');
	var navelements = $('#side-nav a');

	navelements.removeClass('active');
	if(dir === 'down') {
		nextIndex = curIndex+1;
	}
	if(dir === 'up') {
		nextIndex = curIndex;
	}

	if(nextIndex >= 0 && nextIndex < navelements.length) {

		navelements.eq(nextIndex).addClass('active');
		respo_menu.eq(nextIndex).addClass('current');
	}



}

/**
 * Scroll to element
 */
function scrollToEle(ele,duration,offset) {
	jumpTo = true;
	if(mobileCheck()) {
		duration = 0;
	}
	$('html, body').animate({
		scrollTop: (ele.offset().top + offset)
	},duration, function() {
		setTimeout(function(){jumpTo = false;},500);
	});
}
function setNextSlide(element,direction) {

	var curIndex = element.index();

	var nextSlide = 0;
	if(direction == 'down' ) {
		nextSlide = curIndex;
	}
	else if(direction == 'up') {
		nextSlide = curIndex - 1 ;
	}
	if(nextSlide < 0 ) nextSlide = 0;

	$('.slide').removeClass('active-slide');
	$('.slide').eq(nextSlide).addClass('active-slide');

	return nextSlide;



}

function addHashToUrl($url)
{

	if ('' == $url || undefined == $url) {
		$url = '_'; // it is empty hash because if put empty string here then browser will scroll to top of page
	}
	$hash = $url.replace(/^.*#/, '');
	var $fx, $node = jQuery('#' + $hash);
	if ($node.length) {
		$fx = jQuery('<div></div>')
			.css({
				position:'absolute',
				visibility:'hidden',
				top: jQuery(window).scrollTop() + 'px'
			})
			.attr('id', $hash)
			.appendTo(document.body);
		$node.attr('id', '');
	}
	document.location.hash = $hash;
	if ($node.length) {
		$fx.remove();
		$node.attr('id', $hash);
	}

}