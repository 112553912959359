//CookieHinweis
var privacyPolicyCookie = getCookie("acceptPrivacyPolicy");
var cookiebanner = document.getElementById("cookiebanner");
if(privacyPolicyCookie == "yes"){
	cookiebanner.classList.add("hidden-cookie");
}
else {
	cookiebanner.classList.remove("hidden-cookie");
}


function setDataPrivacyCookie() {
	setCookie("acceptPrivacyPolicy", "yes",365);
	cookiebanner.classList.add("hidden-cookie");
}

function setCookie(cname, cvalue, exdays) {
	var d = new Date();
	d.setTime(d.getTime() + (exdays*24*60*60*1000));
	var expires = "expires="+ d.toUTCString();
	document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

function getCookie(cname) {
	var name = cname + "=";
	var decodedCookie = decodeURIComponent(document.cookie);
	var ca = decodedCookie.split(';');
	for(var i = 0; i <ca.length; i++) {
		var c = ca[i];
		while (c.charAt(0) == ' ') {
			c = c.substring(1);
		}
		if (c.indexOf(name) == 0) {
			return c.substring(name.length, c.length);
		}
	}
	return "";
}